import * as React from 'react';
import { Layout } from 'src/components/layout/layout';
import { DonateSection } from 'src/components/donate/section';

const DonatePage = () => {
  return (
    <Layout>
      <DonateSection />
    </Layout>
  );
};
export default DonatePage;
